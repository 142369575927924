import {ADMIN_DETAIL} from '../action/type'
const INITIAL_STATE = {
  detail: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_DETAIL:
      if (payload.status) {
        return {
          ...state,
          detail: payload,
        };
      }    
    default:
      return state;
  }
};
