import { Suspense, lazy } from "react";
import './App.css';
import { Provider } from "react-redux";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './../src/assets/logo.png';

const AppRouter = lazy(() => import("./routes"));
function App() {
  return (
    <Provider store={store}>
      <Suspense 
        fallback={
          <div className="loading">
            <img src={logo} alt="" />
            <span>Loading...</span>
          </div>
        }
      >
        <AppRouter />
      </Suspense>
    </Provider>
  );
}
export default App;