import thunk from "redux-thunk";
import root from './redux/reducer';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger';

const initialState = {};
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

export default store;